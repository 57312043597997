import React, { FC, useState } from 'react';
import Button from 'components/common/Button';
import Container from 'react-bootstrap/Container';
import GatsbyImage from 'components/common/GatsbyImage';
import './StoreFinderRedirect.scss';

const StoreFinderRedirect: FC<PageContent.IStoreFinderRedirect> = ({
  title,
  label,
  button,
  ariaButton,
  queryString,
  image,
  imageAlt,
}) => {
  const [query, setQuery] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setQuery(value);
  };

  return (
    <div className="store-finder-redirect" data-testid="store-finder-redirect">
      <Container fluid>
        {title ? <h2 className="store-finder-redirect__title">{title}</h2> : null}
        <div className="store-finder-redirect__grid">
          <div className="store-finder-redirect__image">
            <GatsbyImage
              objectFit="contain"
              className="store-finder-redirect__image-img"
              fluid={image}
              alt={imageAlt}
            />
          </div>
          <form
            action={`https://www.google.com/maps/search/${queryString.replace(
              /{{inputValue}}/,
              query
            )}`}
            target="_blank"
            className="store-finder-redirect__form"
          >
            {label ? (
              <label className="store-finder-redirect__label" htmlFor="store-finder-input">
                {label}
              </label>
            ) : null}
            <div className="store-finder-redirect__input">
              <input
                value={query}
                onChange={handleChange}
                type="text"
                id="store-finder-input"
                name="store-finder-input"
              />
            </div>
            <Button
              ariaLabel={ariaButton}
              classes="store-finder-redirect__btn"
              variant="link"
              type="submit"
            >
              {button}
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default StoreFinderRedirect;
