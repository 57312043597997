import React, { FC } from 'react';

import GatsbyImage from 'components/common/GatsbyImage';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import { ShopButtonProps } from './model';

const ShopButton: FC<ShopButtonProps> = ({ linkToShop, ariaLabel, image, imageAlt }) => (
  <div className="product-details__buy" data-testid="shop-button">
    <Button
      to={linkToShop?.[0].url}
      target={linkToShop?.[0].target}
      ariaLabel={ariaLabel}
      variant="buy"
    >
      {linkToShop?.[0].name}
      {image?.fallbackUrl ? (
        <GatsbyImage
          isLazyLoading={false}
          objectFit="contain"
          className="product-details__btn-logo"
          fluid={image}
          alt={imageAlt}
        />
      ) : (
        <IconCustom icon="chevron-right" />
      )}
    </Button>
  </div>
);

export default ShopButton;
