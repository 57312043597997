import React, { FC } from 'react';
import classNames from 'classnames';
import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from '../common/DangerouslySetInnerHtml';
import { IGavirepairBlock } from './model';
import './GavirepairBlock.scss';

const GavirepairBlock: FC<IGavirepairBlock> = ({
  titleImage,
  titleImageAlt,
  description,
  image,
  imageAlt,
  listImage,
  listImageAlt,
  listItemMarkerImage,
  list,
  footerDescription,
  listImagePosition = 'left',
}) => {
  const listImageClasses = classNames('gavirepair-block__list-image', `image-${listImagePosition}`);

  return (
    <div className="container-fluid">
      <div className="gavirepair-block">
        <div className="gavirepair-block__info-side">
          <GatsbyImage
            fluid={titleImage}
            alt={titleImageAlt}
            className="gavirepair-block__title-image"
          />
          <DangerouslySetInnerHtml
            element="div"
            html={description}
            className="gavirepair-block__description"
          />
          <div className="gavirepair-block__list-wrapper">
            <GatsbyImage fluid={listImage} alt={listImageAlt} className={listImageClasses} />
            <DangerouslySetInnerHtml
              element="div"
              html={list}
              className="gavirepair-block__list"
              style={{ '--li-background': `url(${listItemMarkerImage.fallbackUrl})` }}
            />
          </div>
          <DangerouslySetInnerHtml
            html={footerDescription}
            element="div"
            className="gavirepair-block__footer-description"
          />
        </div>
        <div className="gavirepair-block__image-side">
          <GatsbyImage fluid={image} alt={imageAlt} className="gavirepair-block__title-image" />
        </div>
      </div>
    </div>
  );
};

export default GavirepairBlock;
